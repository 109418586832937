import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const Language = {
  All: 'all',
  Slovak: 'sk',
  Czech: 'cz',
  English: 'en',
  German: 'de',
  Spanish: 'es',
  French: 'fr',
  Italian: 'it',
  Polish: 'pl',
  Russian: 'ru',
} as const
export type LanguageType = (typeof Language)[keyof typeof Language]
export const LanguageDefault = Language.Slovak

export function useLanguage() {
  const { t } = useI18n()

  const languageOptions = ref<ValueObjectOption<LanguageType>[]>([
    {
      value: Language.Slovak,
      title: t('cms.site.languageValues.slovak'),
    },
    {
      value: Language.Czech,
      title: t('cms.site.languageValues.czech'),
    },
    {
      value: Language.English,
      title: t('cms.site.languageValues.english'),
    },
    {
      value: Language.German,
      title: t('cms.site.languageValues.german'),
    },
    {
      value: Language.Spanish,
      title: t('cms.site.languageValues.spanish'),
    },
    {
      value: Language.French,
      title: t('cms.site.languageValues.french'),
    },
    {
      value: Language.Italian,
      title: t('cms.site.languageValues.italian'),
    },
    {
      value: Language.Polish,
      title: t('cms.site.languageValues.polish'),
    },
    {
      value: Language.Russian,
      title: t('cms.site.languageValues.russian'),
    },
    {
      value: Language.All,
      title: t('cms.site.languageValues.all'),
    },
  ])

  const getLanguageOption = (value: LanguageType) => {
    return languageOptions.value.find((item) => item.value === value)
  }

  return {
    languageOptions,
    getLanguageOption,
  }
}
