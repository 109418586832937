import type { Embed, EmbedEntityIdentity, EmbedEntityType } from '@/components/anzutap/types/Embed'
import { useEmbedImageFactory } from '@/components/anzutap/factory/EmbedImageFactory'
import { useEmbedRelatedFactory } from '@/components/anzutap/factory/EmbedRelatedFactory'
import type { IntegerIdNullable } from '@anzusystems/common-admin'
import { useEmbedCustomFactory } from '@/components/anzutap/factory/EmbedCustomFactory'
import { type EmbedNameType, EmbedNodeName } from '@/components/anzutap/nodes/nodes'
import { useEmbedPollFactory } from '@/components/anzutap/factory/EmbedPollFactory'
import { useEmbedQuizFactory } from '@/components/anzutap/factory/EmbedQuizFactory'
import { useEmbedMinuteFactory } from '@/components/anzutap/factory/EmbedMinuteFactory'
import { useEmbedCrossBoxFactory } from '@/components/anzutap/factory/EmbedCrossBoxFactory'
import { useEmbedTimelineFactory } from '@/components/anzutap/factory/EmbedTimelineFactory'
import { useEmbedFaqFactory } from '@/components/anzutap/factory/EmbedFaqFactory'
import { useEmbedWeatherFactory } from '@/components/anzutap/factory/EmbedWeatherFactory'
import { useEmbedExternalFactory } from '@/components/anzutap/factory/EmbedExternalFactory'
import { useEmbedGalleryFactory } from '@/components/anzutap/factory/EmbedGalleryFactory'
import { useEmbedReviewFactory } from '@/components/anzutap/factory/EmbedReviewFactory'
import { useEmbedImageInlineFactory } from '@/components/anzutap/factory/EmbedImageInlineFactory'
import { useEmbedVideoFactory } from '@/components/anzutap/factory/EmbedVideoFactory'
import { useEmbedAudioFactory } from '@/components/anzutap/factory/EmbedAudioFactory'
import { useEmbedDangerBoxFactory } from '@/components/anzutap/factory/EmbedDangerBoxFactory'

export function useEmbedFactory() {
  const createDefault = (name: EmbedNameType, id = ''): Embed => {
    switch (name) {
      case EmbedNodeName.Image: {
        const { createDefault: createDefaultImage } = useEmbedImageFactory()
        return createDefaultImage(id)
      }
      case EmbedNodeName.Related: {
        const { createDefault: createDefaultRelated } = useEmbedRelatedFactory()
        return createDefaultRelated(id)
      }
      case EmbedNodeName.Custom: {
        const { createDefault: createDefaultCustom } = useEmbedCustomFactory()
        return createDefaultCustom(id)
      }
      case EmbedNodeName.Poll: {
        const { createDefault: createDefaultPoll } = useEmbedPollFactory()
        return createDefaultPoll(id)
      }
      case EmbedNodeName.Quiz: {
        const { createDefault: createDefaultQuiz } = useEmbedQuizFactory()
        return createDefaultQuiz(id)
      }
      case EmbedNodeName.Minute: {
        const { createDefault: createDefaultMinute } = useEmbedMinuteFactory()
        return createDefaultMinute(id)
      }
      case EmbedNodeName.CrossBox: {
        const { createDefault: createDefaultCrossBox } = useEmbedCrossBoxFactory()
        return createDefaultCrossBox(id)
      }
      case EmbedNodeName.DangerBox: {
        const { createDefault: createDefaultDangerBox } = useEmbedDangerBoxFactory()
        return createDefaultDangerBox(id)
      }
      case EmbedNodeName.Timeline: {
        const { createDefault: createDefaultTimeline } = useEmbedTimelineFactory()
        return createDefaultTimeline(id)
      }
      case EmbedNodeName.Faq: {
        const { createDefault: createDefaultFaq } = useEmbedFaqFactory()
        return createDefaultFaq(id)
      }
      case EmbedNodeName.Weather: {
        const { createDefault: createDefaultWeather } = useEmbedWeatherFactory()
        return createDefaultWeather(id)
      }
      case EmbedNodeName.External: {
        const { createDefault: createDefaultExternal } = useEmbedExternalFactory()
        return createDefaultExternal(id)
      }
      case EmbedNodeName.Gallery: {
        const { createDefault: createDefaultGallery } = useEmbedGalleryFactory()
        return createDefaultGallery(id)
      }
      case EmbedNodeName.Review: {
        const { createDefault: createDefaultReview } = useEmbedReviewFactory()
        return createDefaultReview(id)
      }
      case EmbedNodeName.ImageInline: {
        const { createDefault: createDefaultImageInline } = useEmbedImageInlineFactory()
        return createDefaultImageInline(id)
      }
      case EmbedNodeName.Video: {
        const { createDefault: createDefaultVideo } = useEmbedVideoFactory()
        return createDefaultVideo(id)
      }
      case EmbedNodeName.Audio: {
        const { createDefault: createDefaultAudio } = useEmbedAudioFactory()
        return createDefaultAudio(id)
      }
      default: {
        throw new Error('Unsupported embed name in useEmbedFactory!')
      }
    }
  }

  const createIdentity = (type: EmbedEntityType, id: IntegerIdNullable): EmbedEntityIdentity => {
    return {
      type,
      id,
    }
  }

  return {
    createDefault,
    createIdentity,
  }
}
