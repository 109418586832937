import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindArticleList } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'
import { ArticleListLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindArticleListFactory() {
  const create = (): ContentItemKindArticleList => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      url: '',
      listSiteGroup: null,
      listSite: null,
      author: null,
      rubrics: [],
      requiredKeywords: [],
      optionalKeywords: [],
      image: null,
      allowListing: true,
      itemsLimit: 20,
      listLayout: ArticleListLayoutDefault,
      useOverline: false,
      privateArticles: false,
      maxPage: 50,
      maxPageReachedRedirectPage: null,
      bottomText: '',
      bottomUrl: '',
      teleportedIdentifiers: {},
      statsDisplayEnabled: false,
      boxPositionIncludes: [],
      discriminator: ContentItemDiscriminator.ArticleList,
      _resourceName: 'contentItemKindArticleList',
    }
  }

  return {
    create,
  }
}
