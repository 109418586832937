import { ref } from 'vue'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import { isNull, useAlerts, usePagination } from '@anzusystems/common-admin'
import { fetchPropagationSuggestList } from '@/services/api/cms/propagationSuggestApi'
import { storeToRefs } from 'pinia'
import { usePropagationSuggestOneStore } from '@/stores/cms/propagationSuggestStore'
import type { PropagationSuggestStatusType } from '@/model/cms/valueObject/PropagationSuggestStatus'
import { PropagationSuggestStatus } from '@/model/cms/valueObject/PropagationSuggestStatus'
import { usePropagationSuggestFilter } from '@/model/cms/filter/PropagationSuggestFilter'
import type { PropagationSuggest } from '@/types/cms/PropagationSuggest'

const { showErrorsDefault } = useAlerts()

const recommendItemsLoading = ref(false)
const stackItemsLoading = ref(false)
const trashItemsLoading = ref(false)

const listLoading = ref(false)

export const usePropagationSuggestListActions = () => {
  const propagationSuggestOneStore = usePropagationSuggestOneStore()
  const {
    recommendItems,
    stackItems,
    trashItems,
    recommendItemsHasMore,
    stackItemsHasMore,
    trashItemsHasMore,
    recommendItemsPage,
    stackItemsPage,
    trashItemsPage,
  } = storeToRefs(propagationSuggestOneStore)
  const listItems = ref<PropagationSuggest[]>([])

  const fetchItems = async (inboxId: IntegerId, status: PropagationSuggestStatusType, pagination: Pagination) => {
    const filter = usePropagationSuggestFilter()
    filter.inbox.model = inboxId
    filter.status.model = status

    return await fetchPropagationSuggestList(pagination, filter)
  }

  const fetchRecommendedItems = async (inboxId: IntegerId) => {
    const pagination = usePagination()
    recommendItemsPage.value = recommendItemsPage.value + 1
    pagination.page = recommendItemsPage.value
    recommendItemsLoading.value = true
    try {
      recommendItems.value = [
        ...recommendItems.value,
        ...(await fetchItems(inboxId, PropagationSuggestStatus.Recommended, pagination)),
      ]
      if (!isNull(pagination.hasNextPage)) {
        recommendItemsHasMore.value = pagination.hasNextPage
      }
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      recommendItemsLoading.value = false
    }
  }

  const fetchStackItems = async (inboxId: IntegerId) => {
    const pagination = usePagination()
    stackItemsPage.value = stackItemsPage.value + 1
    pagination.page = stackItemsPage.value
    stackItemsLoading.value = true
    try {
      stackItems.value = [
        ...stackItems.value,
        ...(await fetchItems(inboxId, PropagationSuggestStatus.Stack, pagination)),
      ]
      if (!isNull(pagination.hasNextPage)) {
        stackItemsHasMore.value = pagination.hasNextPage
      }
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      stackItemsLoading.value = false
    }
  }

  const fetchTrashItems = async (inboxId: IntegerId) => {
    const pagination = usePagination()
    trashItemsPage.value = trashItemsPage.value + 1
    pagination.page = trashItemsPage.value
    trashItemsLoading.value = true
    try {
      trashItems.value = [
        ...trashItems.value,
        ...(await fetchItems(inboxId, PropagationSuggestStatus.Trash, pagination)),
      ]
      if (!isNull(pagination.hasNextPage)) {
        trashItemsHasMore.value = pagination.hasNextPage
      }
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      trashItemsLoading.value = false
    }
  }

  const fetchAllInboxItems = async (inboxId: IntegerId, statuses: PropagationSuggestStatusType[] = []) => {
    const fetchingStatuses = []
    if (!statuses.length || statuses.includes(PropagationSuggestStatus.Recommended)) {
      fetchingStatuses.push(fetchRecommendedItems(inboxId))
    }
    if (!statuses.length || statuses.includes(PropagationSuggestStatus.Stack)) {
      fetchingStatuses.push(fetchStackItems(inboxId))
    }
    if (!statuses.length || statuses.includes(PropagationSuggestStatus.Trash)) {
      fetchingStatuses.push(fetchTrashItems(inboxId))
    }

    await Promise.all(fetchingStatuses)
  }

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchPropagationSuggestList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }

    return {
      listLoading,
      fetchList,
      listItems,
    }
  }

  return {
    listItems,
    fetchList,
    fetchAllInboxItems,
    fetchRecommendedItems,
    fetchStackItems,
    fetchTrashItems,
    recommendItemsLoading,
    stackItemsLoading,
    trashItemsLoading,
  }
}
