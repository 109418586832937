<script setup lang="ts">
import { useArticleStandardIcon } from '@/model/cms/valueObject/ArticleStandardIcon'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { isArticleKindMinute } from '@/types/cms/ArticleKind/ArticleKindMinute'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { MinuteTopic } from '@/types/cms/MinuteTopic'
import { WebEntityType } from '@/types/cms/TargetPosition'
import type { CollabRoom } from '@/types/Collab'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import ArticleIntentionRemoteAutocomplete from '@/views/cms/articleIntention/components/ArticleIntentionRemoteAutocomplete.vue'
import DesignSettingsRemoteAutocomplete from '@/views/cms/designSettings/components/DesignSettingsRemoteAutocomplete.vue'
import CachedDeskChip from '@/views/cms/desk/components/CachedDeskChip.vue'
import LayoutTemplateRemoteAutocomplete from '@/views/cms/layoutTemplate/components/LayoutTemplateRemoteAutocomplete.vue'
import MinuteTopicSelectTable from '@/views/cms/minuteTopic/components/MinuteTopicSelectTable.vue'
import RubricRemoteAutocomplete from '@/views/cms/rubric/components/RubricRemoteAutocomplete.vue'
import SiteRemoteAutocomplete from '@/views/cms/site/components/SiteRemoteAutocomplete.vue'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { AFormValueObjectOptionsSelect, ARow, useCollabHelpers, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import UserRemoteAutocomplete from '@/views/cms/user/components/UserRemoteAutocomplete.vue'
import { cmsClient } from '@/services/api/clients/cmsClient.ts'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const { createCollabFieldConfig } = useCollabHelpers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { cachedUsers } = useCachedUsers()

const { v$ } = useArticleUpdateValidation(article)
cmsClient()

const onMinuteTopicBrowseConfirm = (data: Array<MinuteTopic>) => {
  if (data.length === 0 || !isArticleKindMinute(article.value)) return
  article.value.minuteTopics = data.map((item) => item.id)
}

const { articleStandardIconOptions } = useArticleStandardIcon()
</script>

<template>
  <ARow>
    <SiteRemoteAutocomplete
      v-model="article.site"
      :v="v$.article.site"
      :site-group-id="article.siteGroup"
      :collab="createCollabFieldConfig('site', collabRoom, cachedUsers)"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <RubricRemoteAutocomplete
      v-model="article.rubric"
      :v="v$.article.rubric"
      required
      :label="t('cms.articleKind.widget.baseSettings.mainRubric')"
      :site-id="article.site"
      :collab="createCollabFieldConfig('rubric', collabRoom, cachedUsers)"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow v-if="isArticleKindStandard(article)">
    <ArticleIntentionRemoteAutocomplete
      v-model="article.intention"
      :v="v$.article.intention"
      required
      :collab="createCollabFieldConfig('intention', collabRoom, cachedUsers)"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <LayoutTemplateRemoteAutocomplete
      v-model="article.layoutTemplate"
      :v="v$.article.layoutTemplate"
      :web-entity-type="WebEntityType.ArticleStandard"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow v-if="isArticleKindStandard(article)">
    <AFormValueObjectOptionsSelect
      v-model="article.attributesStandard.icon"
      :items="articleStandardIconOptions"
      :label="t('cms.articleKind.model.attributesStandard.icon')"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <DesignSettingsRemoteAutocomplete
      v-model="article.designSettings"
      :v="v$.article.designSettings"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow v-if="isArticleKindStandard(article)" :title="t('cms.articleKind.model.desk')">
    <CachedDeskChip :id="article.desk" />
  </ARow>
  <ARow v-if="isArticleKindMinute(article)">
    TODO: demo only for now<br>
    {{ article.minuteTopics }}
    <MinuteTopicSelectTable
      :max-count="10"
      @on-confirm="onMinuteTopicBrowseConfirm"
    />
  </ARow>
  <ARow>
    <UserRemoteAutocomplete
      v-model="article.owners"
      multiple
      :label="t('cms.articleKind.model.owners')"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
</template>
