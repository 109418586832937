import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { SYSTEM_CMS } from '@/model/systems'
import { ContentItemDiscriminatorDefault } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { LayoutTemplate } from '@/types/cms/LayoutTemplate'
import {
  type GroupedPageContents,
  type MultiPageUpdateKeywordsDto,
  type Page,
  type PageContent,
  type PageDuplicateDto,
  PageIndexingTypeDefault,
} from '@/types/cms/Page'
import { dateTimeNow, type IntegerId, type IntegerIdNullable } from '@anzusystems/common-admin'
import { useDocumentFactory } from '@/components/anzutap/factory/DocumentFactory'

export function usePageFactory() {
  const { createContentItemKind } = useContentItemKindFactory()
  const { createEmptyDocument } = useDocumentFactory()

  const createPage = (): Page => ({
    id: 0,
    texts: {
      title: '',
      description: '',
    },
    seo: {
      title: '',
      description: '',
      slug: '',
      keywords: '',
      indexingType: PageIndexingTypeDefault,
    },
    analytics: {
      rempPropertyToken: '',
    },
    seoImage: null,
    flags: {
      useLinkedListFromParent: true,
      useDesignSettingsFromParent: true,
      useAdvertSettingsFromParent: true,
      enabledFollow: false,
      template: false,
      notificationFollowEnabled: false,
      useSearchPageFromParent: true,
      useSeoImageFromParent: true,
      useAnalyticsFromParent: true,
      useDesignSettingsHeaderWithMainHeadline: false,
      rssEnabled: false,
      statsDisplayEnabled: false,
      shownAsRelated: true,
    },
    monitoring: {
      enabled: false,
      daysThreshold: 0,
    },
    dates: {
      lastContentAddedAt: null,
    },
    enabled: true,
    parent: null,
    rubric: null,
    siteGroup: null,
    site: null,
    notificationKey: '',
    layoutTemplate: null,
    image: null,
    desk: null,
    advertSettings: null,
    designSettings: null,
    newsletter: null,
    owners: [],
    supervisors: [],
    linkedList: null,
    excludedAutoDistributions: [],
    mainContent: null,
    searchPage: null,
    siteName: '',
    contents: [],
    createdAt: dateTimeNow(),
    modifiedAt: dateTimeNow(),
    createdBy: null,
    modifiedBy: null,
    _resourceName: 'page',
    _system: SYSTEM_CMS,
  })

  const createPageContent = (targetPositionId: IntegerId): PageContent => ({
    id: 0,
    position: 0,
    enabled: true,
    texts: {
      title: '',
    },
    attributes: {
      main: false,
    },
    page: null,
    contentItem: createContentItemKind(ContentItemDiscriminatorDefault),
    targetPosition: targetPositionId,
    createdAt: dateTimeNow(),
    modifiedAt: dateTimeNow(),
    createdBy: null,
    modifiedBy: null,
    _resourceName: 'pageContent',
    _system: SYSTEM_CMS,
  })

  const createPageDuplicateDto = (pageId: IntegerIdNullable): PageDuplicateDto => ({
    page: pageId,
    texts: {
      title: '',
      description: '',
    },
    seo: {
      slug: '',
    },
    contentItemsData: {
      rubric: null,
      parent: null,
      pageHeaderBody: createEmptyDocument(),
      keywords: [],
    }
  })

  const createMultiPageUpdateKeywordsDto = (): MultiPageUpdateKeywordsDto => ({
    pages: [],
    addKeywords: [],
    removeKeywords: [],
  })

  const createGroupedPageContents = (page: Page, pageLayoutTemplate: LayoutTemplate) => {
    const groupedPageContents: GroupedPageContents = {}
    for (const layoutTemplateTargetPosition of pageLayoutTemplate.layoutTemplateTargetPositions) {
      groupedPageContents[layoutTemplateTargetPosition.targetPosition] = []
    }
    for (const content of page.contents) {
      if (Object.hasOwn(groupedPageContents, content.targetPosition)) {
        groupedPageContents[content.targetPosition].push(content)
      }
    }
    return groupedPageContents
  }

  return {
    createPage,
    createPageContent,
    createPageDuplicateDto,
    createMultiPageUpdateKeywordsDto,
    createGroupedPageContents,
  }
}
