import type { PropagationSuggest } from '@/types/cms/PropagationSuggest'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { PropagationSuggestStatusType } from '@/model/cms/valueObject/PropagationSuggestStatus'
import { PropagationSuggestStatus } from '@/model/cms/valueObject/PropagationSuggestStatus'

export const usePropagationSuggestOneStore = defineStore('cmsPropagationSuggestStore', () => {
  const recommendItems = ref<PropagationSuggest[]>([])
  const stackItems = ref<PropagationSuggest[]>([])
  const trashItems = ref<PropagationSuggest[]>([])

  const recommendItemsHasMore = ref(false)
  const stackItemsHasMore = ref(false)
  const trashItemsHasMore = ref(false)

  const recommendItemsPage = ref(0)
  const stackItemsPage = ref(0)
  const trashItemsPage = ref(0)

  function reset(statuses: PropagationSuggestStatusType[] = []) {
    if (!statuses.length || statuses.includes(PropagationSuggestStatus.Recommended)) {
      resetRecommendItems()
    }
    if (!statuses.length || statuses.includes(PropagationSuggestStatus.Stack)) {
      resetStackItems()
    }
    if (!statuses.length || statuses.includes(PropagationSuggestStatus.Trash)) {
      resetTrashItems()
    }
  }

  function resetRecommendItems() {
    recommendItems.value = []
    recommendItemsPage.value = 0
  }

  function resetStackItems() {
    stackItems.value = []
    stackItemsPage.value = 0
  }

  function resetTrashItems() {
    trashItems.value = []
    trashItemsPage.value = 0
  }

  return {
    recommendItems,
    stackItems,
    trashItems,
    recommendItemsHasMore,
    stackItemsHasMore,
    trashItemsHasMore,
    recommendItemsPage,
    stackItemsPage,
    trashItemsPage,
    reset,
    resetRecommendItems,
    resetStackItems,
    resetTrashItems,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePropagationSuggestOneStore, import.meta.hot))
}
