import { reactive } from 'vue'
import { type Filter, makeFilterHelper, type MakeFilterOptions } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/propagationSuggestApi'
import type { PropagationSuggestStatusType } from '@/model/cms/valueObject/PropagationSuggestStatus'

const makeFilter: <T>(options: Partial<MakeFilterOptions<T>>) => Filter<T> = makeFilterHelper(SYSTEM_CMS, ENTITY)

export function usePropagationSuggestListFilter() {
  const filter = reactive({
    id: {
      ...makeFilter<number>({ name: 'id' }),
    },
    articleDocId: {
      ...makeFilter<string>({ name: 'articleDocId' }),
    },
    inbox: {
      ...makeFilter<number>({ name: 'inbox' }),
    },
  })

  return {
    filter,
  }
}

export function usePropagationSuggestFilter() {
  return reactive({
    status: {
      ...makeFilter<PropagationSuggestStatusType>({ name: 'status', field: 'attributes.status' }),
    },
    inbox: {
      ...makeFilter<number>({ name: 'inbox' }),
    },
  })
}
