import { fetchBoxListByIds } from '@/services/api/cms/boxApi'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'
import type { Box, BoxMinimal } from '@/types/cms/Box'

const mapFullToMinimal = (box: Box): BoxMinimal => ({
  id: box.id,
  title: box.texts.title,
})

const mapIdToMinimal = (id: IntegerId): BoxMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Box,
  BoxMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchBoxListByIds)

export const useCachedBoxes = () => {
  return {
    addManualToCachedBoxes: addManual,
    addManualMinimalToCachedBoxes: addManualMinimal,
    addToCachedBoxes: add,
    fetchCachedBoxes: fetch,
    toFetchCachedBoxes: toFetch,
    cachedBoxes: cache,
    hasCachedBox: has,
    getCachedBox: get,
    isLoadedCachedBox: isLoaded,
  }
}
