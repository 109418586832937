<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import type { CollabRoom } from '@/types/Collab'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { AFormTextarea, ARow, useCollabHelpers, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { createCollabFieldConfig } = useCollabHelpers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { v$ } = useArticleUpdateValidation(article)

const { cachedUsers } = useCachedUsers()
</script>

<template>
  <ARow>
    <AFormTextarea
      v-model="article.texts.overline"
      :v="v$.article.texts.overline"
      :collab="createCollabFieldConfig('texts.overline', collabRoom, cachedUsers)"
      counter
      :disabled="readonly ? true : undefined"
      persistent-counter
      :maxlength="255"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
</template>
