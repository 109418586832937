import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import { apiAnyRequest, apiCreateOne, apiFetchList, apiUpdateOne } from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { PropagationSuggest } from '@/types/cms/PropagationSuggest'
import type { PropagationSuggestStatusType } from '@/model/cms/valueObject/PropagationSuggestStatus'
import type { PropagationSuggestConfirmationType } from '@/model/cms/valueObject/PropagationSuggestConfirmation'

const END_POINT = '/adm/v1/propagation-suggest'
export const ENTITY = 'propagationSuggest'

export const fetchPropagationSuggestList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<PropagationSuggest[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const createPropagationSuggest = (data: PropagationSuggest) =>
  apiCreateOne<PropagationSuggest>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updatePropagationSuggest = (id: IntegerId, data: PropagationSuggest) =>
  apiUpdateOne<PropagationSuggest>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const updatePropagationSuggestStatus = (id: IntegerId, propagationSuggestStatus: PropagationSuggestStatusType) =>
  apiAnyRequest<PropagationSuggest>(
    cmsClient,
    'PATCH',
    END_POINT + '/:id/status/' + propagationSuggestStatus,
    { id },
    null,
    SYSTEM_CMS,
    ENTITY
  )

export const updatePropagationSuggestConfirmation = (id: IntegerId, confirmation: PropagationSuggestConfirmationType) =>
  apiAnyRequest<PropagationSuggest>(
    cmsClient,
    'PATCH',
    END_POINT + '/:id/confirmation/' + confirmation,
    { id },
    null,
    SYSTEM_CMS,
    ENTITY
  )
