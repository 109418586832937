import { createUser, fetchUser, fetchUserList, fetchUserListByIds, updateUser } from '@/services/api/cms/userApi'
import { useUserOneStore } from '@/stores/cms/userStore'
import type { User } from '@/types/cms/User'
import { useCachedBoxes } from '@/views/cms/box/composables/cachedBoxes'
import { useCachedDesks } from '@/views/cms/desk/composables/cachedDesks'
import { useCachedInboxes } from '@/views/cms/inbox/composables/cachedInboxes'
import { useCachedRubrics } from '@/views/cms/rubric/composables/cachedRubrics'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { useCachedStages } from '@/views/cms/stage/composables/cachedStages'
import { UserValidationSymbol } from '@/views/cms/user/composables/userValidations'
import { useCachedPermissionGroups } from '@/views/common/permissionGroup/composables/cachedPermissionGroups'
import {
  type FilterBag,
  type IntegerId,
  isInt,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>(['id'])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useUserListActions = () => {
  const listItems = ref<User[]>([])

  const { addToCachedPermissionGroups, fetchCachedPermissionGroups } = useCachedPermissionGroups()

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchUserList(pagination, filterBag)
      listItems.value.forEach((user) => addToCachedPermissionGroups(user.permissionGroups))
      fetchCachedPermissionGroups()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    listLoading,
    datatableHiddenColumns,
    fetchList,
    listItems,
  }
}

export const useUserDetailActions = () => {
  const { addToCachedPermissionGroups, fetchCachedPermissionGroups } = useCachedPermissionGroups()
  const { addToCachedSites, fetchCachedSites } = useCachedSites()
  const { addToCachedRubrics, fetchCachedRubrics } = useCachedRubrics()
  const { addToCachedBoxes, fetchCachedBoxes } = useCachedBoxes()
  const { addToCachedDesks, fetchCachedDesks } = useCachedDesks()
  const { addToCachedStages, fetchCachedStages } = useCachedStages()
  const { addToCachedInboxes, fetchCachedInboxes } = useCachedInboxes()

  const userOneStore = useUserOneStore()
  const { user } = storeToRefs(userOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const res = await fetchUser(id)
      user.value = res
      addToCachedPermissionGroups(res.permissionGroups)
      fetchCachedPermissionGroups()
      addToCachedSites(res.allowedSites, res.mainSite)
      fetchCachedSites()
      addToCachedRubrics(res.mainRubric)
      fetchCachedRubrics()
      addToCachedBoxes(res.viewableBoxes, res.editableBoxes)
      fetchCachedBoxes()
      addToCachedDesks(res.desks)
      addToCachedDesks(res.followingDesks)
      addToCachedDesks(res.editingDesks)
      fetchCachedDesks()
      addToCachedStages(res.followingStages, res.editingStages)
      fetchCachedStages()
      addToCachedInboxes(res.allowedInboxes)
      fetchCachedInboxes()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    user,
    detailLoading,
    fetchData,
    resetStore: userOneStore.reset,
  }
}

export const useUserCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: UserValidationSymbol })
  const { addToCachedPermissionGroups, fetchCachedPermissionGroups } = useCachedPermissionGroups()
  const userOneStore = useUserOneStore()
  const { user } = storeToRefs(userOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const res = await fetchUser(id)
      user.value = res
      addToCachedPermissionGroups(res.permissionGroups)
      fetchCachedPermissionGroups()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((user: User) => void) | undefined = undefined) => {
    if (!isInt(userOneStore.user.id)) return
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateUser(userOneStore.user.id, userOneStore.user)
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((user: User) => void) | undefined = undefined) => {
    saveButtonLoading.value = true
    try {
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await createUser(userOneStore.user)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    saveButtonLoading,
    detailLoading,
    user,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: userOneStore.reset,
  }
}

export const useUserSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const users = await fetchUserList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>users.map((user: User) => ({
      title: user.person.fullName,
      value: user.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const users = await fetchUserListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>users.map((user: User) => ({
      title: user.person.fullName,
      value: user.id,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}
