<script lang="ts" setup>
import { ACL, useAuth } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import { envConfig } from '@/services/EnvConfigService'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { can, canForSome } = useAuth()

const resolveListGroups = () => {
  return {
    contentLibrary: canForSome([
      ACL.CMS_ARTICLE_ARCHIVE_UI,
      ACL.CMS_GALLERY_UI,
      ACL.CMS_POLL_UI,
      ACL.CMS_QUIZ_UI,
      ACL.CMS_REVIEW_UI,
      ACL.CMS_TIMELINE_UI,
      ACL.CMS_FAQ_UI,
      ACL.CMS_COUNTDOWN_UI,
      ACL.CMS_CROSS_BOX_UI,
      ACL.CMS_DANGER_BOX_UI,
    ]),
    minute: canForSome([ACL.CMS_MINUTE_TOPIC_UI]),
    keyword: canForSome([ACL.CMS_KEYWORD_UI]),
    box: canForSome([ACL.CMS_BOX_UI, ACL.CMS_BOX_GROUP_UI, ACL.CMS_INBOX_UI]),
    newsletter: canForSome([ACL.CMS_NEWSLETTER_UI, ACL.CMS_NEWSLETTER_PROMO_UI]),
    people: canForSome([
      ACL.CMS_PERSON_UI,
      ACL.CMS_ORGANIZATION_UI,
      ACL.CMS_EVENT_UI,
      ACL.CMS_PERSON_POSITION_UI,
      ACL.CMS_PERSON_PAGE_UI,
      ACL.CMS_ORGANIZATION_PAGE_UI,
    ]),
    author: canForSome([ACL.CMS_SUPER_AUTHOR_UI, ACL.CMS_AUTHOR_UI, ACL.CMS_NEWSROOM_SECTION_UI]),
    desk: canForSome([ACL.CMS_DESK_UI, ACL.CMS_TASK_UI, ACL.CMS_ARTICLE_INTENTION_UI]),
    authorization: canForSome([ACL.CMS_ARTICLE_INTENTION_UI, ACL.CMS_USER_UI, ACL.CMS_PERMISSION_GROUP_UI]),
    system: canForSome([
      ACL.CMS_SITE_UI,
      ACL.CMS_SITE_GROUP_UI,
      ACL.CMS_RUBRIC_UI,
      ACL.CMS_ROUTE_UI,
      ACL.CMS_ROUTE_SETTINGS_UI,
      ACL.CMS_LINKED_LIST_UI,
      ACL.CMS_STAGE_UI,
      ACL.CMS_JOB_UI,
      ACL.CMS_CUSTOM_FORM_UI,
      ACL.CMS_TRENDING_ARTICLE_GROUP_LIST_UI,
      ACL.CMS_ADVERT_SETTINGS_UI,
      ACL.CMS_DESIGN_SETTINGS_UI,
      ACL.CMS_LAYOUT_TEMPLATE_UI,
      ACL.CMS_TARGET_POSITION_UI,
      ACL.CMS_PROMO_LINK_UI,
      ACL.CMS_PAYWALL_UI,
      ACL.CMS_EXTERNAL_SNIPPET_UI,
      ACL.CMS_PUBLIC_EXPORT_UI,
      ACL.CMS_LOG_UI,
    ]),
  }
}

const showListGroup = computed(() => resolveListGroups())
</script>

<template>
  <div>
    <VList
      density="compact"
      nav
      color="primary"
    >
      <VListItem
        :to="{ name: ROUTE.SYSTEM.DASHBOARD }"
        prepend-icon="mdi-monitor-dashboard"
        :title="t('sidebar.dashboard')"
      />
      <VListItem
        v-if="can(ACL.CMS_ARTICLE_UI)"
        :to="{ name: ROUTE.CMS.ARTICLE.LIST }"
        prepend-icon="mdi-file-document-outline"
        :title="t('sidebar.content.article')"
      />
      <VListItem
        v-if="can(ACL.CMS_MINUTE_UI)"
        :to="{ name: ROUTE.CMS.ARTICLE_MINUTE.LIST }"
        prepend-icon="mdi-file-document-outline"
        :title="t('sidebar.content.articleMinute')"
      />
      <VListGroup v-if="showListGroup.contentLibrary">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.contentLibrary.title')"
            prepend-icon="mdi-library-outline"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_ARTICLE_ARCHIVE_UI)"
          :to="{ name: ROUTE.CMS.ARTICLE_ARCHIVE.LIST }"
          :title="t('sidebar.content.articleArchive')"
        />
        <VListItem
          v-if="can(ACL.CMS_GALLERY_UI)"
          :to="{ name: ROUTE.CMS.GALLERY.LIST }"
          :title="t('sidebar.content.gallery')"
        />
        <VListItem
          v-if="can(ACL.CMS_POLL_UI)"
          :to="{ name: ROUTE.CMS.POLL.LIST }"
          :title="t('sidebar.content.poll')"
        />
        <VListItem
          v-if="can(ACL.CMS_QUIZ_UI)"
          :to="{ name: ROUTE.CMS.QUIZ.LIST }"
          :title="t('sidebar.content.quiz')"
        />
        <VListItem
          v-if="can(ACL.CMS_REVIEW_UI)"
          :to="{ name: ROUTE.CMS.REVIEW.LIST }"
          :title="t('sidebar.content.review')"
        />
        <VListItem
          v-if="can(ACL.CMS_TIMELINE_UI)"
          :to="{ name: ROUTE.CMS.TIMELINE.LIST }"
          :title="t('sidebar.content.timeline')"
        />
        <VListItem
          v-if="can(ACL.CMS_FAQ_UI)"
          :to="{ name: ROUTE.CMS.FAQ.LIST }"
          :title="t('sidebar.content.faq')"
        />
        <VListItem
          v-if="can(ACL.CMS_COUNTDOWN_UI)"
          :to="{ name: ROUTE.CMS.COUNTDOWN.LIST }"
          :title="t('sidebar.content.countdown')"
        />
        <VListItem
          v-if="can(ACL.CMS_CROSS_BOX_UI)"
          :to="{ name: ROUTE.CMS.CROSS_BOX.LIST }"
          :title="t('sidebar.content.crossBox')"
        />
        <VListItem
          v-if="can(ACL.CMS_DANGER_BOX_UI)"
          :to="{ name: ROUTE.CMS.DANGER_BOX.LIST }"
          :title="t('sidebar.content.dangerBox')"
        />
      </VListGroup>
      <VListItem
        v-if="can(ACL.CMS_DM_UI)"
        :to="{ name: ROUTE.CMS.DISTRIBUTION_MANAGEMENT.LIST }"
        prepend-icon="mdi-view-list"
        :title="t('sidebar.content.distributionManagement')"
      />
      <VListItem
        v-if="envConfig.contentHub.enabled && can(ACL.CONTENT_HUB_EXTERNAL_CONTENT_UI)"
        :to="{ name: ROUTE.CONTENT_HUB.EXTERNAL_CONTENT.LIST }"
        prepend-icon="mdi-newspaper-variant"
        :title="t('sidebar.externalContent.title')"
      />
      <VListItem
        v-if="can(ACL.CMS_AUTO_DISTRIBUTION_UI)"
        :to="{ name: ROUTE.CMS.AUTO_DISTRIBUTION.LIST }"
        prepend-icon="mdi-chevron-up-box-outline"
        :title="t('sidebar.content.autoDistribution')"
      />
      <VListItem
        v-if="can(ACL.CMS_PAGE_UI)"
        :to="{ name: ROUTE.CMS.PAGE.LIST }"
        prepend-icon="mdi-file-cabinet"
        :title="t('sidebar.content.page')"
      />
      <VListGroup v-if="showListGroup.minute">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.minute.title')"
            prepend-icon="mdi-timeline-clock-outline"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_MINUTE_TOPIC_UI)"
          :to="{ name: ROUTE.CMS.MINUTE_TOPIC.LIST }"
          :title="t('sidebar.content.minuteTopic')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.keyword">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.keywords.title')"
            prepend-icon="mdi-file-key-outline"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_KEYWORD_UI)"
          :to="{ name: ROUTE.CMS.KEYWORD.LIST }"
          :title="t('sidebar.content.keyword')"
        />
        <VListItem
          v-if="can(ACL.CMS_KEYWORD_UI)"
          :to="{ name: ROUTE.CMS.KEYWORD_MANAGEMENT.LIST }"
          :title="t('sidebar.content.keywordManagement')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.box">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.boxes.title')"
            prepend-icon="mdi-file-table-box-outline"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_BOX_UI)"
          :to="{ name: ROUTE.CMS.BOX.LIST }"
          :title="t('sidebar.content.box')"
        />
        <VListItem
          v-if="can(ACL.CMS_BOX_GROUP_UI)"
          :to="{ name: ROUTE.CMS.BOX_GROUP.LIST }"
          :title="t('sidebar.content.boxGroup')"
        />
        <VListItem
          v-if="can(ACL.CMS_INBOX_UI)"
          :to="{ name: ROUTE.CMS.INBOX.LIST }"
          :title="t('sidebar.content.inbox')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.newsletter">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.content.newsletter')"
            prepend-icon="mdi mdi-account-group"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_NEWSLETTER_UI)"
          :to="{ name: ROUTE.CMS.NEWSLETTER.LIST }"
          :title="t('sidebar.content.newsletter')"
        />
        <VListItem
          v-if="can(ACL.CMS_NEWSLETTER_PROMO_UI)"
          :to="{ name: ROUTE.CMS.NEWSLETTER_PROMO.LIST }"
          :title="t('sidebar.content.newsletterPromo')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.people">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.people.title')"
            prepend-icon="mdi mdi-account-group"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_PERSON_UI)"
          :to="{ name: ROUTE.CMS.PERSON.LIST }"
          :title="t('sidebar.content.person')"
        />
        <VListItem
          v-if="can(ACL.CMS_ORGANIZATION_UI)"
          :to="{ name: ROUTE.CMS.ORGANIZATION.LIST }"
          :title="t('sidebar.content.organization')"
        />
        <VListItem
          v-if="can(ACL.CMS_EVENT_UI)"
          :to="{ name: ROUTE.CMS.EVENT.LIST }"
          :title="t('sidebar.content.event')"
        />
        <VListItem
          v-if="can(ACL.CMS_PERSON_POSITION_UI)"
          :to="{ name: ROUTE.CMS.PERSON_POSITION.LIST }"
          :title="t('sidebar.content.person_position')"
        />
        <VListItem
          v-if="can(ACL.CMS_PERSON_PAGE_UI)"
          :to="{ name: ROUTE.CMS.PERSON_PAGE.LIST }"
          :title="t('sidebar.content.person_page')"
        />
        <VListItem
          v-if="can(ACL.CMS_ORGANIZATION_PAGE_UI)"
          :to="{ name: ROUTE.CMS.ORGANIZATION_PAGE.LIST }"
          :title="t('sidebar.content.organization_page')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.author">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.authors.title')"
            prepend-icon="mdi-account-group-outline"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_SUPER_AUTHOR_UI)"
          :to="{ name: ROUTE.CMS.SUPER_AUTHOR.LIST }"
          :title="t('sidebar.content.superAuthor')"
        />
        <VListItem
          v-if="can(ACL.CMS_AUTHOR_UI)"
          :to="{ name: ROUTE.CMS.AUTHOR.LIST }"
          :title="t('sidebar.content.author')"
        />
        <VListItem
          v-if="can(ACL.CMS_NEWSROOM_SECTION_UI)"
          :to="{ name: ROUTE.CMS.NEWSROOM_SECTION.LIST }"
          :title="t('sidebar.content.newsroomSection')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.desk">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.desk.title')"
            prepend-icon="mdi-desk"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_DESK_UI)"
          :to="{ name: ROUTE.CMS.DESK.LIST }"
          :title="t('sidebar.content.desk')"
        />
        <VListItem
          v-if="can(ACL.CMS_TASK_UI)"
          :to="{ name: ROUTE.CMS.TASK.LIST }"
          :title="t('sidebar.content.task')"
        />
        <VListItem
          v-if="can(ACL.CMS_ARTICLE_INTENTION_UI)"
          :to="{ name: ROUTE.CMS.ARTICLE_INTENTION.LIST }"
          :title="t('sidebar.content.articleIntention')"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.authorization">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.authorizations.title')"
            prepend-icon="mdi-account-key"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_ARTICLE_INTENTION_UI)"
          :to="{ name: ROUTE.CMS.WIZARD.LIST }"
          :title="t('sidebar.content.wizard')"
        />
        <VListItem
          v-if="can(ACL.CMS_USER_UI)"
          :to="{ name: ROUTE.CMS.USER.LIST }"
          :title="t('sidebar.common.anzuUser')"
          data-cy="navbar-user-permissions"
        />
        <VListItem
          v-if="can(ACL.CMS_PERMISSION_GROUP_UI)"
          :to="{ name: ROUTE.COMMON.PERMISSION_GROUP.LIST }"
          :title="t('sidebar.common.permissionGroup')"
          data-cy="navbar-permission-group"
        />
      </VListGroup>
      <VListGroup v-if="showListGroup.system">
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-bind="listGroupProps"
            :title="t('sidebar.system.title')"
            prepend-icon="mdi-cogs"
          />
        </template>
        <VListItem
          v-if="can(ACL.CMS_SITE_UI)"
          :to="{ name: ROUTE.CMS.SITE.LIST }"
          :title="t('sidebar.content.site')"
        />
        <VListItem
          v-if="can(ACL.CMS_SITE_GROUP_UI)"
          :to="{ name: ROUTE.CMS.SITE_GROUP.LIST }"
          :title="t('sidebar.content.siteGroup')"
        />
        <VListItem
          v-if="can(ACL.CMS_RUBRIC_UI)"
          :to="{ name: ROUTE.CMS.RUBRIC.LIST }"
          :title="t('sidebar.content.rubric')"
        />
        <VListItem
          v-if="can(ACL.CMS_ROUTE_UI)"
          :to="{ name: ROUTE.CMS.ROUTE.LIST }"
          :title="t('sidebar.content.route')"
        />
        <VListItem
          v-if="can(ACL.CMS_ROUTE_SETTINGS_UI)"
          :to="{ name: ROUTE.CMS.ROUTE_SETTINGS.LIST }"
          :title="t('sidebar.content.routeSettings')"
        />
        <VListItem
          v-if="can(ACL.CMS_LINKED_LIST_UI)"
          :to="{ name: ROUTE.CMS.LINKED_LIST.LIST }"
          :title="t('sidebar.content.linkedList')"
        />
        <VListItem
          v-if="can(ACL.CMS_STAGE_UI)"
          :to="{ name: ROUTE.CMS.STAGE.LIST }"
          :title="t('sidebar.content.stage')"
        />
        <VListItem
          v-if="can(ACL.CMS_JOB_UI) && can(ACL.CMS_JOB_READ)"
          :to="{ name: ROUTE.CMS.JOB.LIST }"
          :title="t('sidebar.content.job')"
          data-cy="job-settings"
        />
        <VListItem
          v-if="can(ACL.CMS_CUSTOM_FORM_UI)"
          :to="{ name: ROUTE.COMMON.CUSTOM_FORM.LIST }"
          :title="t('sidebar.content.customForm')"
        />
        <VListItem
          v-if="can(ACL.CMS_TRENDING_ARTICLE_GROUP_LIST_UI)"
          :to="{ name: ROUTE.CMS.TRENDING_ARTICLE_GROUP_LIST.LIST }"
          :title="t('sidebar.content.trendingArticleGroupList')"
        />
        <VListItem
          v-if="can(ACL.CMS_ADVERT_SETTINGS_UI)"
          :to="{ name: ROUTE.CMS.ADVERT_SETTINGS.LIST }"
          :title="t('sidebar.content.advertSettings')"
        />
        <VListItem
          v-if="can(ACL.CMS_ADVERT_SETTINGS_UI)"
          :to="{ name: ROUTE.CMS.ARTICLE_ADVERT_SETTINGS.LIST }"
          :title="t('sidebar.content.articleAdvertSettings')"
        />
        <VListItem
          v-if="can(ACL.CMS_DESIGN_SETTINGS_UI)"
          :to="{ name: ROUTE.CMS.DESIGN_SETTINGS.LIST }"
          :title="t('sidebar.content.designSettings')"
        />
        <VListItem
          v-if="can(ACL.CMS_LAYOUT_TEMPLATE_UI)"
          :to="{ name: ROUTE.CMS.LAYOUT_TEMPLATE.LIST }"
          :title="t('sidebar.content.layoutTemplate')"
        />
        <VListItem
          v-if="can(ACL.CMS_TARGET_POSITION_UI)"
          :to="{ name: ROUTE.CMS.TARGET_POSITION.LIST }"
          :title="t('sidebar.content.targetPosition')"
        />
        <VListItem
          v-if="can(ACL.CMS_PROMO_LINK_UI)"
          :to="{ name: ROUTE.CMS.PROMO_LINK.LIST }"
          :title="t('sidebar.content.promoLink')"
        />
        <VListItem
          v-if="can(ACL.CMS_PAYWALL_UI)"
          :to="{ name: ROUTE.CMS.PAYWALL.LIST }"
          :title="t('sidebar.content.paywall')"
        />
        <VListItem
          v-if="can(ACL.CMS_EXTERNAL_SNIPPET_UI)"
          :to="{ name: ROUTE.CMS.EXTERNAL_SNIPPET.LIST }"
          :title="t('sidebar.content.externalSnippet')"
        />
        <VListItem
          v-if="can(ACL.CMS_PUBLIC_EXPORT_UI)"
          :to="{ name: ROUTE.CMS.PUBLIC_EXPORT.LIST }"
          :title="t('sidebar.content.publicExport')"
        />
        <VListItem
          v-if="can(ACL.CMS_LOG_UI)"
          :to="{ name: ROUTE.COMMON.LOG.LIST }"
          :title="t('sidebar.common.log')"
        />
        <VListItem
          v-if="can(ACL.CMS_LOG_UI) && envConfig.collab.enabled"
          :to="{ name: ROUTE.CMS._PLAYGROUND.COLLAB_DETAIL }"
          :title="t('sidebar.content._playground.collab')"
        />
      </VListGroup>
    </VList>
    <!-- <TestRouteSwitch
      v-if="envConfig.anzutapDebug"
      :from="{ name: ROUTE.CMS.ARTICLE.EMPTY }"
      :to="{ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: 30000350 } }"
    /> -->
  </div>
</template>
