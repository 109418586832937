import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'
import type { EmbedDangerBoxAware, EmbedDangerBoxCreateUpdateDto } from '@/components/anzutap/types/EmbedDangerBox'

export function useEmbedDangerBoxFactory() {
  const createDefault = (id = ''): EmbedDangerBoxAware => {
    return {
      id,
      embeddedDangerBox: null,
      discriminator: 'dangerBox',
      _resourceName: 'embedKindDangerBox',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedDangerBoxCreateUpdateDto => {
    return {
      id,
      embeddedDangerBox: null,
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
