import { useUserFactory } from '@/model/cms/factory/UserFactory'
import type { User } from '@/types/cms/User'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserOneStore = defineStore('cmsUserOneStore', () => {
  const { createDefault } = useUserFactory()

  const user = ref<User>(createDefault())

  function reset() {
    user.value = createDefault()
  }

  return {
    user,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserOneStore, import.meta.hot))
}
