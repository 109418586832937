import { acceptHMRUpdate, defineStore } from 'pinia'
import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { useAuthorKindFactory } from '@/model/cms/factory/AuthorKind/AuthorKindFactory'
import { ref, shallowRef } from 'vue'
import type { Editor } from '@tiptap/vue-3'

export const useAuthorKindOneStore = defineStore('cmsAuthorKindOneStore', () => {
  const { createAuthorKind } = useAuthorKindFactory()

  const author = ref(createAuthorKind())
  const bodyEditor = shallowRef<Editor | undefined>(undefined)
  const bodyEditorReady = ref(false)

  function setAuthor(newAuthor: AuthorKind) {
    author.value = newAuthor
  }

  function reset() {
    author.value = createAuthorKind()
    bodyEditor.value?.destroy()
    bodyEditor.value = undefined
    bodyEditorReady.value = false
  }

  return {
    author,
    bodyEditor,
    bodyEditorReady,
    setAuthor,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthorKindOneStore, import.meta.hot))
}
