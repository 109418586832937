import type { Site } from '@/types/cms/Site'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'

export const SiteValidationSymbol = Symbol.for('anzu:cms:site-validation-scope')

export function useSiteValidation(site: Ref<Site>) {
  const { maxLength, minLength, minValue, maxValue, required, url, email, slug } = useValidate()

  const rules = {
    site: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      slug: {
        required,
        slug,
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
      domain: {
        required,
        url,
        minLength: minLength(6),
        maxLength: maxLength(255),
      },
      linkedList: {
        required,
      },
      bottomMobileLinkedList: {},
      secondaryLinkedList: {},
      siteMapLinkedList: {},
      authorLayoutTemplate: {
        required,
      },
      galleryLayoutTemplate: {
        required,
      },
      forumLayoutTemplate: {
        required,
      },
      advertSettings: {},
      designSettings: {
        required,
      },
      seo: {
        postfix: {
          maxLength: maxLength(255),
        },
        globalMetaTags: {},
        articleMetaTags: {},
        robots: {
          maxLength: maxLength(2000),
        },
      },
      analytics: {
        rempPropertyToken: {
          maxLength: maxLength(255),
        },
        gtmId: {
          maxLength: maxLength(255),
        },
        gemiusId: {
          maxLength: maxLength(255),
        },
        gemiusStreamPlayerId: {
          maxLength: maxLength(255),
        },
        gemiusStreamId: {
          maxLength: maxLength(255),
        },
      },
      rssTexts: {
        title: {
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(2048),
        },
        webMaster: {
          email,
          maxLength: maxLength(255),
        },
      },
      epilogue: {
        maxLength: maxLength(255),
      },
      seoImage: {},
      favoriteBox: {},
      settings: {
        overrideParentContentLockSettings: {},
        lockAfterPercentage: {
          minValue: minValue(0),
          maxValue: maxValue(1),
        },
      },
    },
  }
  const v$ = useVuelidate(rules, { site }, { $scope: SiteValidationSymbol })

  return {
    v$,
  }
}
