import type { User } from '@/types/cms/User'
import { useAnzuUserFactory } from '@anzusystems/common-admin'

export function useUserFactory() {
  const { createAnzuUser } = useAnzuUserFactory('cms', 'user')

  const createDefault = (): User => {
    return {
      ...createAnzuUser(),
      mainSite: null,
      mainRubric: null,
      allowedSites: [],
      viewableBoxes: [],
      editableBoxes: [],
      desks: [],
      followingDesks: [],
      editingDesks: [],
      followingStages: [],
      editingStages: [],
      allowedInboxes: [],
      homePageUi: '',
    }
  }

  return {
    createDefault,
  }
}
