import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindDangerBox } from '@/types/cms/ContentItemKind/ContentItemKindDangerBox'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindDangerBoxFactory() {
  const create = (): ContentItemKindDangerBox => {
    return {
      ...createAbstractContentItemKind(),
      dangerBox: null,
      discriminator: ContentItemDiscriminator.DangerBox,
      _resourceName: 'contentItemKindDangerBox',
    }
  }

  return {
    create,
  }
}
