import { type JobBaseResource, useJobBaseResource } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'

export const JOB_RESOURCE_KEYWORD_KIND_REMOVE_FROM_RELATED = 'jobKeywordKindRemoveFromRelated'
export const JOB_RESOURCE_KEYWORD_KIND_REPLACE_ON_RELATED = 'jobKeywordKindReplaceOnRelated'
export const JOB_RESOURCE_KEYWORD_KIND_REINDEX_RELATED = 'jobKeywordKindReindexRelated'
export const JOB_RESOURCE_TARGET_POSITION_REINDEX_RELATED = 'jobTargetPositionReindexRelated'
export const JOB_RESOURCE_RECALCULATE = 'jobRecalculate'
export const JOB_RESOURCE_ROUTE_REGENERATE = 'jobRouteRegenerate'
export const JOB_RESOURCE_UPDATE_FORUM_BY_RUBRIC = 'jobUpdateForumByRubric'
export const JOB_RESOURCE_UPDATE_OWL_BY_RUBRIC = 'jobUpdateOwlByRubric'
export const JOB_RESOURCE_MULTI_BEAM_ARTICLE_UPSERT = 'jobMultiBeamArticleUpsert'

export type JobResource =
  | typeof JOB_RESOURCE_KEYWORD_KIND_REMOVE_FROM_RELATED
  | typeof JOB_RESOURCE_KEYWORD_KIND_REPLACE_ON_RELATED
  | typeof JOB_RESOURCE_KEYWORD_KIND_REINDEX_RELATED
  | typeof JOB_RESOURCE_TARGET_POSITION_REINDEX_RELATED
  | typeof JOB_RESOURCE_RECALCULATE
  | typeof JOB_RESOURCE_ROUTE_REGENERATE
  | typeof JOB_RESOURCE_UPDATE_FORUM_BY_RUBRIC
  | typeof JOB_RESOURCE_UPDATE_OWL_BY_RUBRIC
  | typeof JOB_RESOURCE_MULTI_BEAM_ARTICLE_UPSERT
  | JobBaseResource

export function useJobResource() {
  const { t } = useI18n()

  return useJobBaseResource<JobResource>([
    {
      title: t('cms.job.jobResource.jobKeywordKindRemoveFromRelated'),
      value: JOB_RESOURCE_KEYWORD_KIND_REMOVE_FROM_RELATED,
    },
    {
      title: t('cms.job.jobResource.jobKeywordKindReplaceOnRelated'),
      value: JOB_RESOURCE_KEYWORD_KIND_REPLACE_ON_RELATED,
    },
    {
      title: t('cms.job.jobResource.jobKeywordKindReindexRelated'),
      value: JOB_RESOURCE_KEYWORD_KIND_REINDEX_RELATED,
    },
    {
      title: t('cms.job.jobResource.jobTargetPositionReindexRelated'),
      value: JOB_RESOURCE_TARGET_POSITION_REINDEX_RELATED,
    },
    {
      title: t('cms.job.jobResource.jobRecalculate'),
      value: JOB_RESOURCE_RECALCULATE,
    },
    {
      title: t('cms.job.jobResource.jobRouteRegenerate'),
      value: JOB_RESOURCE_ROUTE_REGENERATE,
    },
    {
      title: t('cms.job.jobResource.jobUpdateForumByRubric'),
      value: JOB_RESOURCE_UPDATE_FORUM_BY_RUBRIC,
    },
    {
      title: t('cms.job.jobResource.jobUpdateOwlByRubric'),
      value: JOB_RESOURCE_UPDATE_OWL_BY_RUBRIC,
    },
    {
      title: t('cms.job.jobResource.jobMultiBeamArticleUpsert'),
      value: JOB_RESOURCE_MULTI_BEAM_ARTICLE_UPSERT,
    },
  ])
}
