import { fetchInboxListByIds } from '@/services/api/cms/inboxApi'
import type { Inbox, InboxMinimal } from '@/types/cms/Inbox'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'

const mapFullToMinimal = (inbox: Inbox): InboxMinimal => ({
  id: inbox.id,
  title: inbox.texts.title,
})

const mapIdToMinimal = (id: IntegerId): InboxMinimal => {
  return { id: id, title: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  Inbox,
  InboxMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchInboxListByIds)

export const useCachedInboxes = () => {
  return {
    addManualToCachedInboxes: addManual,
    addManualMinimalToCachedInboxes: addManualMinimal,
    addToCachedInboxes: add,
    fetchCachedInboxes: fetch,
    toFetchCachedInboxes: toFetch,
    cachedInboxes: cache,
    hasCachedInbox: has,
    getCachedInbox: get,
    isLoadedCachedInbox: isLoaded,
  }
}
