<script lang="ts" setup>
import { useUserFilter } from '@/model/cms/filter/UserFilter'
import { useUserSelectActions } from '@/views/cms/user/composables/userActions'
import { AFormRemoteAutocomplete, type IntegerId, type IntegerIdNullable } from '@anzusystems/common-admin'

withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    clearable?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    clearable: false,
  }
)
const modelValue = defineModel<IntegerIdNullable | IntegerId[]>({ required: true })

const { fetchItems, fetchItemsByIds } = useUserSelectActions()

const innerFilter = useUserFilter()
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    filter-by-field="lastName"
    :multiple="multiple"
    :clearable="clearable"
  />
</template>
