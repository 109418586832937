import { beforeEachRoute } from '@/router/beforeEachRoute'
import { ROUTE } from '@/router/routes'
import { cmsPlaygroundRoutes } from '@/router/routes/cms/_playgroundRoutes'
import { cmsAdvertSettingsRoutes } from '@/router/routes/cms/advertSettingsRoutes'
import { commonAnzuUserRoutes } from '@/router/routes/cms/anzuUser'
import { cmsArticleAdvertSettingsRoutes } from '@/router/routes/cms/articleAdvertSettingsRoutes'
import { cmsArticleArchiveRoutes } from '@/router/routes/cms/articleArchiveRoutes'
import { cmsArticleIntentionRoutes } from '@/router/routes/cms/articleIntentionRoutes'
import { cmsArticleMinuteRoutes } from '@/router/routes/cms/articleMinuteRoutes'
import { cmsArticleRoutes } from '@/router/routes/cms/articleRoutes'
import { cmsAuthorRoutes } from '@/router/routes/cms/authorRoutes'
import { cmsAutoDistributionRoutes } from '@/router/routes/cms/autoDistributionRoutes'
import { cmsBoxGroupRoutes } from '@/router/routes/cms/boxGroupRoutes'
import { cmsBoxRoutes } from '@/router/routes/cms/boxRoutes'
import { cmsCountdownRoutes } from '@/router/routes/cms/countdownRoutes'
import { cmsCrossBoxRoutes } from '@/router/routes/cms/crossBoxRoutes'
import { cmsDesignSettingsRoutes } from '@/router/routes/cms/designSettingsRoutes'
import { cmsDeskRoutes } from '@/router/routes/cms/deskRoutes'
import { cmsDistributionManagementRoutes } from '@/router/routes/cms/distributionManagementRoutes'
import { cmsEventRoutes } from '@/router/routes/cms/eventRoutes'
import { externalContentRoutes } from '@/router/routes/cms/externalContentRoutes'
import { cmsExternalSnippetRoutes } from '@/router/routes/cms/externalSnippetRoutes'
import { cmsFaqRoutes } from '@/router/routes/cms/faqRoutes'
import { cmsGalleryRoutes } from '@/router/routes/cms/galleryRoutes'
import { cmsInboxRoutes } from '@/router/routes/cms/inboxRoutes'
import { cmsJobRoutes } from '@/router/routes/cms/jobRoutes'
import { cmsKeywordManagementRoutes } from '@/router/routes/cms/keywordManagementRoutes'
import { cmsKeywordRoutes } from '@/router/routes/cms/keywordRoutes'
import { cmsLayoutTemplateRoutes } from '@/router/routes/cms/layoutTemplateRoutes'
import { cmsLinkedListRoutes } from '@/router/routes/cms/linkedListRoutes'
import { cmsMinuteTopicRoutes } from '@/router/routes/cms/minuteTopicRoutes'
import { cmsNewsletterPromoRoutes } from '@/router/routes/cms/newsletterPromoRoutes'
import { cmsNewsletterRoutes } from '@/router/routes/cms/newsletterRoutes'
import { cmsNewsroomSectionRoutes } from '@/router/routes/cms/newsroomSectionRoutes'
import { cmsOrganizationPageRoutes } from '@/router/routes/cms/organizationPageRoutes'
import { cmsOrganizationRoutes } from '@/router/routes/cms/organizationRoutes'
import { cmsPageRoutes } from '@/router/routes/cms/pageRoutes'
import { cmsPaywallRoutes } from '@/router/routes/cms/paywallRoutes'
import { cmsPersonPageRoutes } from '@/router/routes/cms/personPageRoutes'
import { cmsPersonPositionRoutes } from '@/router/routes/cms/personPositionRoutes'
import { cmsPersonRoutes } from '@/router/routes/cms/personRoutes'
import { cmsPollRoutes } from '@/router/routes/cms/pollRoutes'
import { cmsPromoLinkRoutes } from '@/router/routes/cms/promoLinkRoutes'
import { cmsPublicExportRoutes } from '@/router/routes/cms/publicExportRoutes'
import { cmsQuizRoutes } from '@/router/routes/cms/quizRoutes'
import { cmsReviewRoutes } from '@/router/routes/cms/reviewRoutes'
import { cmsRouteRoutes } from '@/router/routes/cms/routeRoutes'
import { cmsRouteSettingsRoutes } from '@/router/routes/cms/routeSettingsRoutes'
import { cmsRubricRoutes } from '@/router/routes/cms/rubricRoutes'
import { cmsSiteGroupRoutes } from '@/router/routes/cms/siteGroupRoutes'
import { cmsSiteRoutes } from '@/router/routes/cms/siteRoutes'
import { cmsStageRoutes } from '@/router/routes/cms/stageRoutes'
import { cmsSuperAuthorRoutes } from '@/router/routes/cms/superAuthorRoutes'
import { cmsTargetPositionRoutes } from '@/router/routes/cms/targetPositionRoutes'
import { cmsTaskRoutes } from '@/router/routes/cms/taskRoutes'
import { cmsTimelineRoutes } from '@/router/routes/cms/timelineRoutes'
import { cmsTrendingArticleGroupListRoutes } from '@/router/routes/cms/trendingArticleGroupListRoutes'
import { cmsWizardRoutes } from '@/router/routes/cms/wizardRoutes'
import { commonCustomFormRoutes } from '@/router/routes/common/customForm'
import { commonLogRoutes } from '@/router/routes/common/log'
import { commonPermissionGroupRoutes } from '@/router/routes/common/permissionGroup'
import { systemRoutes } from '@/router/routes/systemRoutes'
import DashboardView from '@/views/system/DashboardView.vue'
import HomepageView from '@/views/system/HomepageView.vue'
import { ANotFoundView } from '@anzusystems/common-admin'
import { createRouter, createWebHistory } from 'vue-router'
import { cmsDangerBoxRoutes } from '@/router/routes/cms/dangerBoxRoutes'

const vueRouter = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: HomepageView,
      name: ROUTE.SYSTEM.HOMEPAGE,
      meta: {
        requiresAuth: true,
        requiredPermissions: [],
        layout: 'AppLayoutFullscreen',
      },
    },
    {
      path: '/dashboard',
      component: DashboardView,
      name: ROUTE.SYSTEM.DASHBOARD,
      meta: {
        requiresAuth: true,
        requiredPermissions: [],
        layout: 'AppLayoutDrawer',
      },
    },
    ...externalContentRoutes,
    ...cmsPlaygroundRoutes,
    ...cmsAdvertSettingsRoutes,
    ...cmsArticleAdvertSettingsRoutes,
    ...cmsArticleArchiveRoutes,
    ...cmsArticleRoutes,
    ...cmsArticleMinuteRoutes,
    ...cmsAuthorRoutes,
    ...cmsDangerBoxRoutes,
    ...cmsBoxGroupRoutes,
    ...cmsBoxRoutes,
    ...cmsDesignSettingsRoutes,
    ...cmsInboxRoutes,
    ...cmsDistributionManagementRoutes,
    ...cmsJobRoutes,
    ...cmsKeywordRoutes,
    ...cmsKeywordManagementRoutes,
    ...cmsLinkedListRoutes,
    ...cmsNewsroomSectionRoutes,
    ...cmsNewsletterRoutes,
    ...cmsNewsletterPromoRoutes,
    ...cmsPageRoutes,
    ...cmsPollRoutes,
    ...cmsQuizRoutes,
    ...cmsReviewRoutes,
    ...cmsPromoLinkRoutes,
    ...cmsRouteRoutes,
    ...cmsRouteSettingsRoutes,
    ...cmsRubricRoutes,
    ...cmsSiteGroupRoutes,
    ...cmsSiteRoutes,
    ...cmsStageRoutes,
    ...cmsSuperAuthorRoutes,
    ...cmsWizardRoutes,
    ...cmsCrossBoxRoutes,
    ...cmsTargetPositionRoutes,
    ...cmsTimelineRoutes,
    ...cmsCountdownRoutes,
    ...cmsFaqRoutes,
    ...cmsGalleryRoutes,
    ...cmsMinuteTopicRoutes,
    ...cmsAutoDistributionRoutes,
    ...cmsLayoutTemplateRoutes,
    ...cmsPersonRoutes,
    ...cmsOrganizationRoutes,
    ...cmsEventRoutes,
    ...cmsOrganizationPageRoutes,
    ...cmsPersonPageRoutes,
    ...cmsPersonPositionRoutes,
    ...cmsPublicExportRoutes,
    ...cmsExternalSnippetRoutes,
    ...commonAnzuUserRoutes,
    ...commonLogRoutes,
    ...commonPermissionGroupRoutes,
    ...commonCustomFormRoutes,
    ...systemRoutes,
    ...cmsArticleIntentionRoutes,
    ...cmsTaskRoutes,
    ...cmsDeskRoutes,
    ...cmsTrendingArticleGroupListRoutes,
    ...cmsPaywallRoutes,
    {
      path: '/:pathMatch(.*)*',
      component: ANotFoundView,
      props: { returnRouteName: ROUTE.SYSTEM.HOMEPAGE },
      name: ROUTE.SYSTEM.NOT_FOUND,
      meta: {
        requiresAuth: false,
        requiredPermissions: [],
        layout: 'AppLayoutFullscreen',
      },
    },
  ],
})

vueRouter.beforeEach(async (to, from, next) => {
  await beforeEachRoute(to, from, next)
})

export const router = vueRouter
